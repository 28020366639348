<template>
  <div class="normal container">
    <van-form @submit="handleSubmit" v-if="!appoint">
      <van-cell-group>
        <van-field center :value="cardLabel" placeholder="请选择" @click="cardTypeShow = true" clickable is-link
                   readonly
                   :rules="[{ required: true, message: '请选择证件类型' }]">
          <div slot="label">
            <div class="van-cell__title mtitle">
              <span>证件类型</span>
              <div class="van-cell__label mlabel">ID</div>
            </div>
          </div>
        </van-field>
        <van-field center v-model="form.cardNo" placeholder="请输入"
                   :rules="[{ required: true, message: '请填写证件号' }, {validator: validateCardNo, message: '请填写正确的证件号'}]">
          <div slot="label">
            <div class="van-cell__title mtitle">
              <span>证件号</span>
              <div class="van-cell__label mlabel">ID No.</div>
            </div>
          </div>
        </van-field>
        <van-field center v-model="form.name" placeholder="请输入"
                   :rules="[{ required: true, message: '请填写姓名' }]">
          <div slot="label">
            <div class="van-cell__title mtitle">
              <span>姓名</span>
              <div class="van-cell__label mlabel">Name</div>
            </div>
          </div>
        </van-field>
        <van-field center v-model="form.mobile" placeholder="请输入" type="tel"
                   :rules="[{ required: true, message: '请填写手机号' }, {validator: validateMobile, message: '请填写正确的手机号码'}]">
          <div slot="label">
            <div class="van-cell__title mtitle">
              <span>手机号</span>
              <div class="van-cell__label mlabel">Mobile Number</div>
            </div>
          </div>
        </van-field>
      </van-cell-group>
      <br><br>
      <van-cell-group>
        <van-field center :value="scenicLabel" placeholder="请选择" @click="scenicShow = true" clickable is-link
                   readonly
                   :rules="[{ required: true, message: '请选择石窟' }]">
          <div slot="label">
            <div class="van-cell__title mtitle">
              <span>石窟选择</span>
              <div class="van-cell__label mlabel">Grottoe</div>
            </div>
          </div>
        </van-field>
        <van-field center :value="form.date" placeholder="请选择" @click="calendarShow = true" clickable is-link
                   readonly
                   :rules="[{ required: true, message: '请选择参观日期' }]">
          <div slot="label">
            <div class="van-cell__title mtitle">
              <span>参观日期</span>
              <div class="van-cell__label mlabel">Date</div>
            </div>
          </div>
        </van-field>
        <van-field center :value="appointTimeRemainLabel" placeholder="请选择" @click="showTimePicker" clickable
                   is-link readonly
                   :rules="[{ required: true, message: '请选择预约时间段' }]">
          <div slot="label">
            <div class="van-cell__title mtitle">
              <span>预约时间段</span>
              <div class="van-cell__label mlabel">Time</div>
            </div>
          </div>
        </van-field>
      </van-cell-group>

      <van-button type="primary" class="btn-primary" round style="margin: 50px auto 0; display: block;"
                  native-type="submit">提交订单
      </van-button>
    </van-form>

    <van-popup v-model="scenicShow" position="bottom">
      <van-picker show-toolbar :columns="scenics" @cancel="scenicShow = false" @confirm="handleScenic"
                  title="选择石窟" value-key="name"/>
    </van-popup>
    <van-popup v-model="cardTypeShow" position="bottom">
      <van-picker show-toolbar :columns="cardTypes" @cancel="cardTypeShow = false" @confirm="handleCardType"
                  title="选择证件类型" value-key="label"/>
    </van-popup>
    <van-popup v-model="timeShow" position="bottom">
      <van-picker show-toolbar :columns="appointTimes" @cancel="timeShow = false" @confirm="handleTime"
                  ref="timePicker"
                  title="选择时间" value-key="remainLabel"/>
    </van-popup>
    <van-calendar v-model="calendarShow" color="#CAD39F" :max-date="maxDate" :formatter="formatter"
                  @confirm="handleDate"/>

    <van-dialog v-model="confirmShow" title="- 您将预约以下石窟 -" show-cancel-button @confirm="submit">
      <div style="text-align: center;">
        <div style="color: #888880; margin: 20px auto 5px;">手机号</div>
        <div>{{ form.mobile }}</div>
        <div style="color: #888880; margin: 20px auto 5px;">证件号</div>
        <div>{{ form.cardNo }}</div>
        <div style="color: #888880; margin: 20px auto 5px;">参观日期</div>
        <div>{{ form.date }}</div>
        <div style="color: #888880; margin: 20px auto 5px;">石窟选择</div>
        <div>{{ scenicLabel }}</div>
        <br>
      </div>
    </van-dialog>
    <succeed :appoint="appoint" v-if="appoint"></succeed>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Calendar, Cell, CellGroup, Field, Form, Picker, Popup, Toast } from 'vant'
import dayjs from 'dayjs'
import 'vant/lib/field/style'
import 'vant/lib/cell/style'
import 'vant/lib/cell-group/style'
import 'vant/lib/calendar/style'
import 'vant/lib/picker/style'
import 'vant/lib/popup/style'
import 'vant/lib/button/style'
import 'vant/lib/form/style'
import Succeed from '../components/Succeed'

Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Calendar)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Button)
Vue.use(Form)

export default {
  name: 'normal',
  components: {
    succeed: Succeed
  },
  data () {
    return {
      winterTimeStart: '10-08',
      winterTimeEnd: '04-30',
      scenics: [],
      calendarShow: false,
      scenicShow: false,
      cardTypeShow: false,
      confirmShow: false,
      timeShow: false,
      scenic: null,
      holidays: [],
      appointTimes: [],
      cardLabel: '',
      scenicLabel: '',
      appointTimeLabel: '',
      appointTimeRemainLabel: '',
      appoint: null,
      cardTypes: [{
        label: '身份证',
        value: 'ID_CARD'
      }, {
        label: '护照',
        value: 'PASSPORT'
      }, {
        label: '港澳台通行证',
        value: 'PERMIT'
      }],
      form: {
        cardType: null,
        cardNo: '',
        name: '',
        mobile: '',
        scenicId: null,
        date: '',
        appointTimeId: null
      },
      cache: {}
    }
  },
  computed: {
    maxDate () {
      return dayjs().add(7, 'day').toDate()
    }
  },
  created () {
    const that = this
    that.$http.get('/v1/scenics?appointed=true').then(res => {
      that.scenics = res.data
    })
    that.$http.get('/v1/dicts?type=HOLIDAY').then(res => {
      that.holidays = res.data
    })
  },
  methods: {
    showTimePicker () {
      const that = this
      if (!this.scenic || !this.form.date) {
        Toast('请先选择石窟和预约日期')
      } else {
        const key = 'time-' + that.scenic.id + '-' + that.form.date
        let remainData = that.cache[key]
        // 是否为冬令时
        console.info(this.form.date, that.isWinterTime(this.form.date))
        if (that.isWinterTime(this.form.date)) {
          that.appointTimes = [...this.scenic.appointTimes].filter(time => {
            return time.startTime >= '10:00:00' && time.endTime <= '18:00:00'
          }).filter(time => time.timeType === 'WINTER')
        } else {
          this.appointTimes = [...this.scenic.appointTimes].filter(time => time.timeType === 'SUMMER')
        }
        // 判断是否过期
        const now = dayjs()
        if (this.form.date === now.format('YYYY-MM-DD')) {
          console.info('this is today')
          that.appointTimes.forEach(time => {
            Vue.set(time, 'remainLabel', time.label)
            Vue.set(time, 'disabled', true)
            time.expired = time.endTime <= now.format('HH:mm')
          })
        } else {
          console.info('no today')
          that.appointTimes.forEach(time => {
            time.expired = false
          })
        }
        // 加载剩余预约数
        if (remainData) {
          that.appointTimes.forEach(time => {
            if (time.expired) {
              return
            }
            const remain = remainData[time.id]
            Vue.delete(time, 'remainLabel')
            Vue.delete(time, 'disabled')
            Vue.set(time, 'remainLabel', time.label + '(剩' + remain + '名额)')
            if (remain <= 0) {
              Vue.set(time, 'disabled', true)
            }
          })
        } else {
          remainData = {}
          that.cache[key] = remainData
          that.appointTimes.forEach(time => {
            if (time.expired) {
              return
            }
            Vue.set(time, 'remainLabel', time.label + '(剩loading名额)')
            that.$http.get('/v1/scenics/' + that.scenic.id + '/times/' + time.id + '/remain?date=' + that.form.date).then(res => {
              Vue.delete(time, 'remainLabel')
              Vue.delete(time, 'disabled')
              Vue.set(time, 'remainLabel', time.label + '(剩' + res.data + '名额)')
              remainData[time.id] = res.data
              if (res.data <= 0) {
                Vue.set(time, 'disabled', true)
              }
            })
          })
        }
        this.timeShow = true
      }
    },
    isWinterTime (date) {
      const timezone = dayjs(date || new Date()).format('MM-DD')
      return !(timezone < this.winterTimeStart && timezone > this.winterTimeEnd)
    },
    formatter (day) {
      const weekDay = day.date.getDay()
      if (weekDay === process.env.VUE_APP_CLOSE_WEEKDAY) {
        const dateStr = dayjs(day.date).format('MM-DD')
        console.info(this.holidays, dateStr)
        const isHoliday = this.holidays.some(holiday => holiday.label === dateStr)
        if (!isHoliday) {
          day.topInfo = '不开放'
          day.type = 'disabled'
        }
      }
      return day
    },
    clearTime () {
      this.appointTimeLabel = ''
      this.form.appointTimeId = null
      this.appointTimeRemainLabel = null
    },
    handleCardType (cardType) {
      this.cardTypeShow = false
      this.cardLabel = cardType.label
      this.form.cardType = cardType.value
    },
    handleScenic (scenic) {
      this.scenicShow = false
      this.scenic = scenic
      this.scenicLabel = scenic.name
      this.form.scenicId = scenic.id
      this.clearTime()
    },
    handleDate (date) {
      this.calendarShow = false
      this.form.date = dayjs(date).format('YYYY-MM-DD')
      this.clearTime()
    },
    handleTime (time) {
      this.timeShow = false
      this.form.appointTimeId = time.id
      this.appointTimeLabel = time.label
      this.appointTimeRemainLabel = time.remainLabel
    },
    handleSubmit () {
      this.confirmShow = true
    },
    validateCardNo (value) {
      if (this.form.cardType === 'ID_CARD') {
        return value.length === 18
      }
      return true
    },
    validateMobile (value) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(this.form.name) && this.form.cardType === 'ID_CARD') {
        return value.length === 11
      }
      return true
    },
    submit () {
      const that = this
      Toast.loading({
        message: '提交中...'
      })
      that.lock('post_appoint_normal') && that.$http.post('/v1/appoints/normals', that.form).then(res => {
        if (res.code === '0') {
          that.unlock('post_appoint_normal')
          Toast.clear()
          that.appoint = {
            appointNo: res.data.appointNo,
            type: 'NORMAL',
            scenic: that.scenicLabel,
            name: that.form.name,
            cardNo: that.form.cardNo,
            date: that.form.date,
            appointTime: that.appointTimeLabel,
            getQrCodeContent: function () {
              return `普通,${this.appointNo},${that.encodeCardNo(this.cardNo)},${this.name},${this.scenic},${this.date},${this.appointTime}`
            }
          }
        }
      })
    }
  }
}
</script>
