<template>
    <div class="success container" v-if="appoint">
        <div style="text-align: center; margin-bottom: 15px;">
            <img src="@/assets/image/succeed.png" width="56" height="56">
            <br><br>
            <span style="font-size: 16px;color: #000000;letter-spacing: 0;">预约成功</span>
        </div>
        <van-cell-group>
            <van-cell title="订单编号" label="Order" title-class="mtitle" label-class="mlabel" value-class="mvalue"
                      :value="appoint.appointNo"></van-cell>
            <van-cell title="姓名" label="Name" title-class="mtitle" label-class="mlabel" value-class="mvalue"
                      :value="appoint.name"></van-cell>
            <van-cell title="证件号" label="ID No." title-class="mtitle" label-class="mlabel" value-class="mvalue"
                      :value="appoint.cardNo"></van-cell>
        </van-cell-group>
        <div style="background-color: #F6F6F6; padding-top: 8px; margin: 0 -12px;"></div>
        <van-cell-group v-if="appoint.type == 'NORMAL'">
            <van-cell title="石窟选择" label="Grottoe" title-class="mtitle" label-class="mlabel" value-class="mvalue"
                      :value="appoint.scenic"></van-cell>
            <van-cell title="参观日期" label="Date" title-class="mtitle" label-class="mlabel" value-class="mvalue"
                      :value="appoint.date"></van-cell>
            <van-cell title="进场时间" label="Time" title-class="mtitle" label-class="mlabel" value-class="mvalue"
                      :value="appoint.appointTime"></van-cell>
        </van-cell-group>
        <van-cell-group v-if="appoint.type == 'VISIT'">
            <van-cell title="参观日期" label="Date" title-class="mtitle" label-class="mlabel" value-class="mvalue"
                      :value="appoint.date"></van-cell>
            <van-cell title="预约石窟" label="Grottoe" title-class="mtitle" label-class="mlabel" value-class="mvalue"
                      :value="appoint.scenics.join('、')"></van-cell>
        </van-cell-group>
        <div style="text-align: center;margin-top: 40px;">
            <div id="qrcode"></div>
            <span style="color: #E02020;">请务必截屏保存预约二维码</span>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import { Row, Col, Cell, CellGroup, Image } from 'vant'
    import QRCode from 'qrcodejs2'
    import 'vant/lib/row/style'
    import 'vant/lib/col/style'
    import 'vant/lib/cell/style'
    import 'vant/lib/cell-group/style'
    import 'vant/lib/image/style'

    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Cell)
    Vue.use(CellGroup)
    Vue.use(Image)
    export default {
        props: {
            appoint: {
                type: Object,
                required: true
            }
        },
        mounted () {
            let appoint = this.appoint
            // eslint-disable-next-line no-new
            new QRCode('qrcode', {
                width: 132,
                height: 132,
                text: appoint.getQrCodeContent(),
                colorDark: '#000',
                colorLight: '#fff',
                correctLevel: QRCode.CorrectLevel.H
            })
        }
    }
</script>
<style lang="scss">
    .success {
        background-color: #FFFFFF;
        margin: 24px 36px 40px;
        padding: 26px 12px 40px;

        .mvalue {
            text-align: left;
            margin-left: 15px;
        }

        #qrcode {
            margin-bottom: 10px;
            img {
                margin: auto;
            }
        }
    }
</style>
